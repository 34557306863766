
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import {Associate} from '@/domain/model/associate';
import {businessStore} from '@/store/modules/business/businessStore';
import BusinessStoreBase from '@/components/BusinessStoreBase.vue';
import {applicationStore} from '@/store/modules/application';
import TextFieldEmail from '@/components/profile/privacy-security/TextFieldEmail.vue';

@Component({
  name: 'dialog-create-associate-profile',
  components: {TextFieldEmail, VcSwitch, GroupAvatar, AvatarWithStatus, BaseDialog},
  mixins: [BusinessStoreBase]
})
export default class DialogCreateAssociateProfile extends Vue {
  @Prop() value!: boolean;

  name: string = '';
  position: string = '';
  email: string = '';
  phoneNumber: string = '';
  role: any;
  autoCreateContact: boolean = true;

  roles: any[] = [
    {name: 'Associate', value: 1},
    {name: 'Admin', value: 3}
  ];

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get business() {
    return applicationStore.business
  }

  async onSendInvite() {
    const form: any = this.$refs.userProfile;
    if (!form.validate()) {
      return;
    }
    let {name, email, position, phoneNumber, autoCreateContact} = this;
    const ok = await businessStore.addUser({
      name,
      email,
      position,
      phoneNumber: phoneNumber,
      role: this.role.value,
      autoCreateContact,
      sendInvite: true,
      mode: 'signInOnly'
    });
    this.show = !ok;
  }

  init() {
    this.name = '';
    this.position = '';
    this.email = '';
    this.phoneNumber = '';
    this.role = null;
    this.autoCreateContact = true;
  }

  created() {
    this.init();
  }

  updated() {
    this.init();
  }
}
