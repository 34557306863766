






































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import SearchView from '@/components/SearchView.vue';
import {Unsubscribe} from 'firebase/firestore';
import DialogSendInvite from '@/components/DialogSendInvite.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import {Associate} from '@/domain/model/associate';
import {applicationStore} from '@/store/modules/application';
import DialogCreateAssociateProfile from '@/components/DialogCreateAssociateProfile.vue';
import DialogEditAssociateProfile from '@/components/DialogEditAssociateProfile.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import {profileStore} from '@/store/modules/profile';
import {extractDate, extractTime} from '@/utils/helpers';
import PopupMenu from '@/components/PopupMenu.vue';
import GpHeader from '@/components/custom/HeaderView.vue';
import GpTabs from '@/components/custom/TabsView.vue';
import GpTab from '@/components/custom/TabView.vue';
import GpTable from '@/components/custom/TableView.vue';
import GpCheckbox from '@/components/custom/CheckboxView.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import PageNotFound from '@/views/PageNotFound.vue';
import ContentNotFound from '@/components/ContentNotFound.vue';
import DialogEditProfile from '@/components/profile/DialogEditProfile.vue';
import {tourStore} from '@/store/modules/tour';

Component.registerHooks(['beforeRouteEnter'])

@Component({
  name: 'users',
  components: {
    ContentNotFound,
    PageNotFound,
    PopupMenu, GpHeader, GpTabs, GpTab, GpTable, GpCheckbox,
    DialogCreateAssociateProfile, DialogEditAssociateProfile, DialogSendInvite, DialogConfirm, DialogEditProfile,
    AvatarWithStatus, SearchView
  },
  filters: {
    extractDate,
    extractTime
  }
})
export default class Users extends mixins(Notifications) {
  @Prop({type: Number, default: 0}) tab

  showDialogSendInvite: boolean = false;
  showDialogAddManually: boolean = false;
  showDialogEditManually: boolean = false;
  showEditProfileDialog: boolean = false;
  userEdit: any | null = null;
  selected: any = {
    activated: [],
    invited: [],
    manually: []
  }

  header = {
    activated: {
      sort: {index: 0, order: 'asc'},
      width: {1: '15%', 2: '14%', 3: '18%', 4: '9%'},
      list: [
        {text: 'Name', sortable: true, value: 'fullName'},
        {text: 'Position', sortable: true, value: 'position'},
        {text: 'Role', sortable: false},
        {text: 'Last login', sortable: false},
        {text: 'Status', sortable: false},
      ]
    },
    invited: {
      sort: {index: 0, order: 'asc'},
      width: {1: '15%', 2: '15%', 3: '15%'},
      list: [
        {text: 'Email', sortable: true, value: 'email'},
        {text: 'Role', sortable: false},
        {text: 'Sent', sortable: false},
        {text: 'Expired', sortable: false}
      ]
    },
    manually: {
      sort: {index: 0, order: 'asc'},
      width: {1: '20%', 2: '15%', 3: '14%', 4: '10%', 5: '10%'},
      list: [
        {text: 'Name', sortable: true, value: 'fullName'},
        {text: 'Email', sortable: true, value: 'email'},
        {text: 'Position', sortable: true, value: 'position'},
        {text: 'Role', sortable: false},
        {text: 'Sent', sortable: false},
        {text: 'Expired', sortable: false}
      ]
    }
  }

  tabs: string[] = ['Activated', 'Invited by Email', 'Added Manually']
  sortBy: string[] = ['fullName', 'position', 'admin', 'status.lastChanged', 'workingStatus.name'];
  sortDesc: boolean[] = [true, true, true, true, true];
  sortInvitedBy: string[] = ['createDate', 'email', 'role'];

  showError: boolean = false;
  timeout: number = 3000;

  onOkClicked?: any;
  onConfirm?: any;
  dialog: boolean = false;
  title: string = '';
  text: string = '';
  confirm: string = ''
  textColor: string = ''
  btnColor: string = 'error'
  search: string = '';
  unsubscribeAssociates?: Unsubscribe | null;
  unsubscribeInvited?: Unsubscribe | null;

  ASSOCIATE_MASK = 1
  ADMIN_MASK = 2
  SUPER_ADMIN_MASK = 4
  notificationMessage: String = 'test';

  get validTab() {
    return typeof this.tab === 'number' && this.tab in [0, 1, 2]
  }

  get business() {
    return applicationStore.business;
  }

  get hideUser() {
    return applicationStore.hideUser;
  }

  get deleteUser() {
    return applicationStore.deleteUser;
  }

  get associates() {
    return businessStore.associates || [];
  }

  get invited() {
    return businessStore.invited || [];
  }

  get activatedUsers() {
    return businessStore.associatesActivated;
  }

  get addedManuallyUsers() {
    return businessStore.associatesInvited;
  }

  get error() {
    return businessStore.error;
  }

  get message() {
    return businessStore.message;
  }

  get uid() {
    return profileStore.t2bUser!.id
  }

  get showGroupPopup() {
    if (this.tab === 0) {
      return this.selected['activated'].length > 1
    } else if (this.tab === 1) {
      return this.selected['invited'].length > 1
    } else {
      return this.selected['manually'].length > 1
    }
  }

  get selectedItemsPopup() {
    let items: any[] = [];
    switch (this.tab) {
      case 0:
        items = this.activatedMenu(null)
        break;
      case 1:
        items = this.invitedMenu
        break;
      case 2:
        items = this.addedManuallyMenu(null)
        break;
    }
    return items
  }

  get selectedTabs() {
    const {tab} = this
    return [tab === 0, tab === 1, tab === 2]
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  @Watch('error')
  onErrorChanged(after, before) {
    if (!!after && after !== before) {
      this.showIssue(after);
    }
  }

  @Watch('message')
  onMessageChanged(after, before) {
    if (!!after && after !== before) {
      this.showInfo(after);
    }
  }

  onSortActivated(index, order) {
    this.header.activated.sort = {index: index, order: order};
  }

  onSortInvited(index, order) {
    this.header.invited.sort = {index: index, order: order};
  }

  onSortManually(index, order) {
    this.header.manually.sort = {index: index, order: order};
  }

  onCheckActivated(id) {
    this.setCheck('activated', id);
  }

  onCheckInvited(id) {
    this.setCheck('invited', id);
  }

  onCheckManually(id) {
    this.setCheck('manually', id);
  }

  setCheck(tab, id) {
    if (this.selected[tab].includes(id)) {
      this.selected[tab].splice(this.selected[tab].indexOf(id), 1);
    } else {
      this.selected[tab].push(id);
    }
  }

  removeCheck(tab, id) {
    this.selected[tab].splice(this.selected[tab].indexOf(id), 1)
  }

  onCheckList(tab) {
    if (this.selected[tab].length == 0) {
      switch (tab) {
        case 'activated':
          this.$set(this.selected, tab, this.activatedUsers.map(item => item.id));
          break;
        case 'invited':
          this.$set(this.selected, tab, this.invited.map(item => item.id));
          break;
        case 'manually':
          this.$set(this.selected, tab, this.addedManuallyUsers.map(item => item.id));
          break;
      }
    } else {
      this.$set(this.selected, tab, []);
    }
  }

  onTab(tab: number) {
    //this.bellNotification()
    //notificationsStore.bellNotification();
    this.$router.push({name: 'users', query: {tab: `${tab}`}});
  }

  activatedMenu(item: any) {
    let items: any[] = []
    if (item) {
      items.push({
        title: 'Edit'
      })
    }
    items.push({
      title: 'Make visible',
      skip: item ?
          (item.id === this.uid || !item.hidden) :
          this.activatedUsers
              .filter(item => this.selected['activated'].includes(item.id))
              .every(item => item.id === this.uid || !item.hidden)
    })
    items.push({
      title: 'Hide',
      skip: item ?
          (item.id === this.uid || item.hidden) :
          this.activatedUsers
              .filter(item => this.selected['activated'].includes(item.id))
              .every(item => item.id === this.uid || item.hidden)
    })
    items.push({
      title: 'Enable',
      skip: item ?
          (item.id === this.uid || !item.disabled) :
          this.activatedUsers
              .filter(item => this.selected['activated'].includes(item.id))
              .every(item => item.id === this.uid || !item.disabled)
    })
    items.push({
      title: 'Disable',
      skip: item ?
          (item.id === this.uid || item.disabled || item.roles?.superAdmin === true) :
          this.activatedUsers
              .filter(item => this.selected['activated'].includes(item.id))
              .filter(user => user.id != this.uid && user.roles?.superAdmin != true)
              .every(item => item.id === this.uid || item.disabled)
    })
    items.push({
      title: 'Remove',
      alert: true,
      skip: item ?
          (item.id === this.uid || item.roles?.superAdmin === true) :
          this.activatedUsers
              .filter(user => this.selected['activated'].includes(user.id))
              .filter(user => user.id != this.uid && user.roles?.superAdmin != true)
              .length == 0
    })
    return items
  }

  get invitedMenu() {
    return [
      {
        title: 'Re-invite'
      },
      {
        title: 'Revoke',
        alert: true
      }
    ]
  }

  addedManuallyMenu(item: any) {
    let items: any[] = []
    if (item) {
      items.push({
        title: 'Edit'
      })
    }
    items.push({
      title: 'Re-invite'
    })
    items.push({
      title: 'Enable',
      skip: item ? !item.disabled :
          this.addedManuallyUsers
              .filter(item => this.selected['manually'].includes(item.id))
              .every(item => !item.disabled)
    })
    items.push({
      title: 'Disable',
      skip: item ? item.disabled :
          this.addedManuallyUsers
              .filter(item => this.selected['manually'].includes(item.id))
              .every(item => item.disabled)
    })
    items.push({
      title: 'Show',
      skip: item ? !item.hidden :
          this.addedManuallyUsers
              .filter(item => this.selected['manually'].includes(item.id))
              .every(item => !item.hidden)
    })
    items.push({
      title: 'Hide',
      skip: item ? item.hidden :
          this.addedManuallyUsers
              .filter(item => this.selected['manually'].includes(item.id))
              .every(item => item.hidden)
    })
    items.push({
      title: 'Revoke',
      alert: true
    })
    return items
  }

  inviteExpired = (date?: Date): string => {
    if (date) {
      const now = new Date();
      const diffMins: number = (now.getTime() - date.getTime()) / 1000 / 60
      // tslint:disable-next-line:no-bitwise
      return diffMins >= 360 ? 'Expired' : `${~~((360 - diffMins) / 60)}h ${~~((360 - diffMins) % 60)}m`
    }
    return 'N/A'
  }

  workingStatusClass(item: Associate) {
    return item.disabled ? 'working-status-disabled'
        : (item.hidden ? 'working-status-hidden' : ((item.workingStatus && item.workingStatus.type === 1) ? 'working-status-active' : 'working-status-away'));
  }

  workingStatusText(item: Associate) {
    return item.disabled ? 'Disabled'
        : (item.hidden ? 'Hidden' : (item.workingStatus ? item.workingStatus.name : (item.status?.online ? 'Absent'  : 'N/A')));       
  }

  searchUser(term) {
    this.search = term;
  }

  onRemoveUser(item: any) {
    this.title = `Do you want to remove user '${item.fullName}'?`;
    this.text = 'Warning: This action can’t be undone.';
    this.textColor = 'text-secondary'
    this.btnColor = 'error'
    this.confirm = 'Yes, remove'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await this.deleteUser(item.id);
        this.removeCheck('activated', item.id)
        this.showInfo(`${item.fullName} was deleted`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    }
    this.dialog = true
  }

  onRemoveUsers() {
    const users = this.activatedUsers
        .filter(item => this.selected['activated'].includes(item.id))
        .filter(item => item.id != this.uid && item.roles?.superAdmin != true)
    const names: string = users.map(user => user.fullName).join(', ')
    this.title = `Do you want to remove user ${names}?`;
    this.text = 'Warning: This action can’t be undone.';
    this.textColor = 'text-secondary'
    this.btnColor = 'error'
    this.confirm = 'Yes, remove'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.deleteUsers(users)
        for (const user of users) {
          this.removeCheck('activated', user.id)
        }
        this.showInfo(`${names} was deleted`)
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true
  }

  async onRevokeInvitedUsers() {
    const users = this.invited.filter(item => this.selected['invited'].includes(item.id))
    await this.revokeUsers(users, 'invited')
  }

  async onRevokeAddedManuallyUsers() {
    const users = this.addedManuallyUsers.filter(item => this.selected['manually'].includes(item.id))
    for (const user of users) {
      try {
        await this.deleteUser(user.id);
        this.removeCheck('manually', user.id)
        this.showInfo(`${user.fullName} was deleted`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    }
  }

  async revokeUsers(users: any[], kind: string) {
    for (const user of users) {
      const ok = await businessStore.revokeUserInvite(user.id)
      if (ok) {
        this.removeCheck(kind, user.id)
        console.log(kind, user.id)
        this.showInfo(`Invite '${user.email}' has been revoked`)
      } else {
        this.showIssue(`Failed to revoke the invite '${user.email}'`)
      }
    }
  }

  async onRevokeInvitedUser(invite: any) {
    const ok = await businessStore.revokeUserInvite(invite.id);
    if (ok) {
      this.removeCheck('invited', invite.id)
      this.showInfo('Invite has been revoked')
    } else {
      this.showIssue('Failed to revoke the invite')
    }
  }

  async onReInviteInvitedUsers() {
    const users = this.invited.filter(item => this.selected['invited'].includes(item.id))
    for (const item of users) {
      try {
        await businessStore.reinviteUser({item, mode: 'signUp'});
      } catch (err) {
        console.error('failed re-invite user', err)
      }
    }
  }

  async onReInviteAddedManuallyUsers() {
    const users = this.addedManuallyUsers.filter(item => this.selected['manually'].includes(item.id))
    for (const item of users) {
      try {
        await businessStore.reinviteExistingUser({item, mode: 'signInOnly'})
      } catch (err) {
        console.error('failed re-invite user', err)
      }
    }
  }

  onDisableActivatedUsers() {
    const users = this.activatedUsers
        .filter(user => user.id != this.uid && user.roles?.superAdmin != true)
        .filter(item => (
            this.selected['activated'].includes(item.id)
            && (item.disabled === false || item.disabled === undefined)
            && item.id !== this.uid))
    this.disableUsers(users)
  }

  onDisableAddedManuallyUsers() {
    const users = this.addedManuallyUsers
        .filter(item => (
            this.selected['manually'].includes(item.id)
            && (item.disabled === false || item.disabled === undefined)))
    this.disableUsers(users)
  }

  disableUsers(users: any[]) {
    const names: string = users.map(user => user.fullName).join(', ')
    this.title = 'Disable Users';
    this.text = `Confirm disabling users: ${names} ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUsers({users: users, disable: true});
        this.showInfo(`${names} was disabled`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onDisableUser(item: any) {
    this.title = 'Disable User';
    this.text = `Confirm disabling user '${item.fullName}' ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUser({uid: item.id, disable: true});
        this.showInfo(`${item.fullName} was disabled`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onEnableActivatedUsers() {
    const users = this.activatedUsers.filter(item => (
        this.selected['activated'].includes(item.id)
        && (item.disabled === true || item.disabled === undefined)
        && item.id !== this.uid))
    this.enableUsers(users)
  }

  onEnableAddedManuallyUsers() {
    const users = this.addedManuallyUsers.filter(item => (
        this.selected['manually'].includes(item.id)
        && (item.disabled === true || item.disabled === undefined)))
    this.enableUsers(users)
  }

  enableUsers(users: any[]) {
    const names: string = users.map(user => user.fullName).join(', ')
    this.title = 'Enable Users';
    this.text = `Confirm enabling users: ${names} ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUsers({users: users, disable: false});
        this.showInfo(`${names} was enabled`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onEnableUser(item: any) {
    this.title = 'Enable User';
    this.text = `Confirm enabling user '${item.fullName}' ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.disableUser({uid: item.id, disable: false});
        this.showInfo(`${item.fullName} was enabled`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onHideActivatedUsers() {
    const users = this.activatedUsers.filter(item => (
        this.selected['activated'].includes(item.id)
        && (item.hidden === false || item.hidden === undefined)
        && item.id !== this.uid))
    this.hideUsers(users)
  }

  onHideAddedManuallyUsers() {
    const users = this.addedManuallyUsers.filter(item => (
        this.selected['manually'].includes(item.id)
        && (item.hidden === false || item.hidden === undefined)))
    this.hideUsers(users)
  }

  hideUsers(users: any[]) {
    const names: string = users.map(user => user.fullName).join(', ')
    this.title = 'Hide Users';
    this.text = `Confirm hiding users ${names} ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.hideUsers({users: users, hidden: true});
        this.showInfo(`${names} was hidden`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onHideUser(item: any) {
    this.title = 'Hide User';
    this.text = `Confirm hiding user '${item.fullName}' ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await this.hideUser({uid: item.id, hidden: true});
        this.showInfo(`${item.fullName} was hidden`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onShowActivatedUsers() {
    const users = this.activatedUsers.filter(item => (
        this.selected['activated'].includes(item.id)
        && (item.hidden === true || item.hidden === undefined)
        && item.id !== this.uid))
    this.showUsers(users)
  }

  onShowAddedManuallyUsers() {
    const users = this.addedManuallyUsers.filter(item => (
        this.selected['manually'].includes(item.id)
        && (item.hidden === true || item.hidden === undefined)))
    this.showUsers(users)
  }

  showUsers(users: any[]) {
    const names: string = users.map(user => user.fullName).join(', ')
    this.title = 'Show Users';
    this.text = `Confirm showing users ${names} ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await applicationStore.hideUsers({users: users, hidden: false});
        this.showInfo(`${names} was unhidden`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  onShowUser(item: any) {
    this.title = 'Show User';
    this.text = `Confirm showing user '${item.fullName}' ?`;
    this.textColor = ''
    this.btnColor = 'primary'
    this.confirm = 'Ok'
    this.onConfirm = async () => {
      this.dialog = false;
      try {
        await this.hideUser({uid: item.id, hidden: false});
        this.showInfo(`${item.fullName} was unhidden`);
      } catch (e: any) {
        this.showIssue(e.message);
      }
    };
    this.dialog = true;
  }

  associateRole(associate): string {
    const roles = associate.roles;
    if (!!roles) {
      return roles.superAdmin ? 'Super Admin' : (roles.admin ? 'Admin' : 'Associate')
    }
    // legacy
    return (associate.superAdmin || associate.owner) ? 'Super Admin' : (associate.admin ? 'Admin' : 'Associate')
  }

  invitedRole(role: number): string {
    return role & this.SUPER_ADMIN_MASK ? 'Super Admin' : (role & this.ADMIN_MASK ? 'Admin' : 'Associate')
  }

  onActivatedMenuItemClick(menuItem: any, item: any) {
    switch (menuItem.title) {
      case 'Edit': {
        this.userEdit = item
        this.showEditProfileDialog = true;
        break
      }
      case 'Enable': {
        this.onEnableUser(item)
        break
      }
      case 'Disable': {
        this.onDisableUser(item)
        break
      }
      case 'Make visible': {
        this.onShowUser(item)
        break
      }
      case 'Hide': {
        this.onHideUser(item)
        break
      }
      case 'Remove': {
        this.onRemoveUser(item)
        break
      }
      default:
    }
  }

  async onInvitedMenuItemClick(menuItem: any, item: any) {
    switch (menuItem.title) {
      case 'Re-invite': {
        try {
          await businessStore.reinviteUser({item, mode: 'signUp'});
        } catch (err) {
          console.error('failed re-invite user', err)
        }
        break
      }
      case 'Revoke': {
        await this.onRevokeInvitedUser(item)
        break
      }
      default:
    }
  }

  async onAddedManuallyMenuItemClick(menuItem: any, item: any) {
    switch (menuItem.title) {
      case 'Edit': {
        this.userEdit = item
        this.showDialogEditManually = true;
        break
      }
      case 'Re-invite': {
        try {
          await businessStore.reinviteExistingUser({item, mode: 'signInOnly'});
        } catch (err) {
          console.error('failed re-invite user', err)
        }
        break
      }
      case 'Enable': {
        this.onEnableUser(item)
        break
      }
      case 'Disable': {
        this.onDisableUser(item)
        break
      }
      case 'Show': {
        this.onShowUser(item)
        break
      }
      case 'Hide': {
        this.onHideUser(item)
        break
      }
      case 'Revoke': {
        this.onRemoveUser(item)
        break
      }
      default:
    }
  }

  async onActivatedGroupActionClick(menuItem: any) {
    switch (menuItem.title) {
      case 'Enable': {
        this.onEnableActivatedUsers()
        break
      }
      case 'Disable': {
        this.onDisableActivatedUsers()
        break
      }
      case 'Make visible':
      case 'Show': {
        this.onShowActivatedUsers()
        break
      }
      case 'Hide': {
        this.onHideActivatedUsers()
        break
      }
      case 'Remove': {
        this.onRemoveUsers()
        break
      }
    }
  }

  async onInvitedGroupActionClick(menuItem: any) {
    switch (menuItem.title) {
      case 'Revoke': {
        await this.onRevokeInvitedUsers()
        break
      }
      case 'Re-invite': {
        await this.onReInviteInvitedUsers()
        break
      }
    }
  }

  async onAddedManuallyGroupActionClick(menuItem: any) {
    switch (menuItem.title) {
      case 'Enable': {
        this.onEnableAddedManuallyUsers()
        break
      }
      case 'Disable': {
        this.onDisableAddedManuallyUsers()
        break
      }
      case 'Make visible':
      case 'Show': {
        this.onShowAddedManuallyUsers()
        break
      }
      case 'Hide': {
        this.onHideAddedManuallyUsers()
        break
      }
      case 'Revoke': {
        await this.onRevokeAddedManuallyUsers()
        break
      }
      case 'Re-invite': {
        await this.onReInviteAddedManuallyUsers()
        break
      }
    }
  }

  onChangesProfile() {
    this.showInfo('Profile was changed');
  }

  beforeDestroy() {
    if (this.unsubscribeAssociates) {
      this.unsubscribeAssociates();
    }
    if (this.unsubscribeInvited) {
      this.unsubscribeInvited();
    }
  }

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.unsubscribeAssociates) {
        vm.unsubscribeAssociates();
      }
      vm.unsubscribeAssociates = await businessStore.loadAssociatesAsync();
      if (vm.unsubscribeInvited) {
        vm.unsubscribeInvited();
      }
      vm.unsubscribeInvited = await businessStore.loadInvitedAsync()
    })
  }
}
